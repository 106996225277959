import React from "react";
import Layout from "../components/Layout/Layout";
import SEO from "../components/Seo";
import Mikan from "mikanjs";

const hero =
    "https://cms2.climacell.co/wp-content/uploads/2019/06/road-hero.jpg";

const industries = [
    {
        name: "交通",
        desc: `最適化したルート計画と不要な遅延の回避`,
        icon:
            "https://cms2.climacell.co/wp-content/uploads/2019/04/icon-solution-TRANSPORTATION.png",
    },
    {
        name: "オンデマンド",
        desc: `最適なルートを選択することにより、<nobr>お客様の待ち時間を減少し</nobr>、<nobr>道路関連の事故を回避</nobr>`,
        icon:
            "https://cms2.climacell.co/wp-content/uploads/2019/03/icon-on-demand.png",
    },
    {
        name: "建設",
        desc: `道路コンディションのせいで大切な時間を<br/>チームが無駄にしないようにする`,
        icon:
            "https://cms2.climacell.co/wp-content/uploads/2019/03/icon-construction.png",
    },
    {
        name: "公益事業",
        desc: `遅延を回避するために必要な情報を<nobr>作業員に提供</nobr>`,
        icon:
            "https://cms2.climacell.co/wp-content/uploads/2019/04/icon-cbam-utilities.png",
    },
];

const benefits = [
    {
        name: "ルート計画を最適化",
        desc: "悪い道路状況にあるエリアを迂回する為の<nobr>代替ルート</nobr>を選択",
        icon:
            "https://cms2.climacell.co/wp-content/uploads/2019/06/icon-optimize-route-planning.png",
    },
    {
        name: "遅延の回避",
        desc: "悪い道路状況による減速や遅延を回避",
        icon:
            "https://cms2.climacell.co/wp-content/uploads/2019/06/icon-avoid-delays.png",
    },
    {
        name: "安全の向上",
        desc:
            "減速、より大きな停止距離、障害物の認識を事前に計画",
        icon:
            "https://cms2.climacell.co/wp-content/uploads/2019/06/icon-increase-safety.png",
    },
    {
        name: "米国内の評価",
        desc: "米国全域の正確な道路レベルの状態を入手",
        icon:
            "https://cms2.climacell.co/wp-content/uploads/2019/06/icon-us-wide-ratings.png",
    },
];

export default ({location}) => (
    <Layout>
        <SEO
            title="正確な道路気象状況予報により、フリートの安全性を向上 | tomorrow.io"
            pathname={location.pathname}
            image={hero}
        />
        <section className="c-section-hero py-6">
            <div className="row mx-0 justify-content-center">
                <div className="col-lg-5 d-flex justify-content-center px-md-0">
                    <div style={{maxWidth: 500}}>
                        <h1 className="h-2-sm mb-4"
                            dangerouslySetInnerHTML={{__html: Mikan(' 正確な道路気象状況予報により、フリートの安全性を向上')}}/>
                        <p className="mb-3"
                           dangerouslySetInnerHTML={{__html: Mikan('ドライバーが道路に繰り出す前に、道路リスクの正確な判断を伝えましょう')}}/>
                        <p className="mb-4"
                           dangerouslySetInnerHTML={{__html: `天候は道路状況に大きな影響力を持ちます。tomorrow.ioの道路<br/>リスクスコアを利用して、ルート計画を良くし、遅延を減少、<br/>安全を向上しましょう。`}}/>
                        <a
                            className="btn btn-lg btn-primary mb-5 text-nowrap"
                            href="https://www.tomorrow.io/api-signup/"
                            id="api-link-form-1"
                        >
                            今すぐ試す
                        </a>
                    </div>
                </div>
                <div className="col-lg-5 d-flex justify-content-center px-md-0">
                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/vvEmkv9q_3c?rel=0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope"
                        allowFullScreen
                        title="tomorrow.io Auto"
                        frameBorder="0"
                    />
                </div>
            </div>
            <div className="text-sideways d-none d-md-block">
                42°21'02.1" N 71°03'27.6" W
            </div>
        </section>

        <section className="c-section bg-light">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-9 text-center">
                        <h2 className="h-3 mb-4">道路リスクスコアとは何でしょう？</h2>
                        <p className="fs-lg mb-4 mb-lg-6"
                           dangerouslySetInnerHTML={{__html: Mikan('tomorrow.ioの5ポイントの評価尺度は、道路で何が起こる可能性があるかを、影響があるエリアに到達する18時間前までドライバーに知らせることが出来ます。リスクスコアは、降水量の激しさ、降水量タイプ、霜の可能性、可視性など、観測して予報した気象を考慮しています。スコア１（低リスク）からスコア５（激しい、かなりのリスク）で評価されます。')}}/>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-4 text-center mb-4 px-lg-5">
                        <h5 className="h-5 ">１＝低い</h5>
                        <p dangerouslySetInnerHTML={{__html: Mikan('現在天候に関連するリスクとして認識できるのは、ほとんどなし。空はいたって快晴、降水の見込みもなし。')}}/>
                    </div>
                    <div className="col-md-4 text-center mb-4 px-lg-5">
                        <h5 className="h-5 ">２＝中程度</h5>
                        <p dangerouslySetInnerHTML={{__html: Mikan('多少の降水、雨または霧やもやによる視界不良に見舞われるでしょう')}}/>
                    </div>
                    <div className="col-md-4 text-center mb-4 px-lg-5">
                        <h5 className="h-5 ">３＝中程度～高い</h5>
                        <p>降水ならびに視界不良、滑りやすい道路
                            <nobr>など</nobr>
                            に見舞われるでしょう
                        </p>
                    </div>
                    <div className="col-md-4 text-center mb-4 px-lg-5">
                        <h5 className="h-5 ">４＝高い</h5>
                        <p dangerouslySetInnerHTML={{__html: Mikan('可視性は低く、降水もおそらく予想され、道路は氷や霜、雪で滑りやすいでしょう。')}}/>
                    </div>
                    <div className="col-md-4 text-center mb-4 px-lg-5">
                        <h5 className="h-5 ">５＝ひどい</h5>
                        <p dangerouslySetInnerHTML={{__html: Mikan('可視性はかなり悪く、降水も確実に予想されます。大量の雪に見舞われ、道路は滑りやすいでしょう。さらに洪水にも警戒が必要です。')}}/>
                    </div>
                </div>
            </div>
        </section>

        <section className="c-section bg-testimonials h-auto text-white">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-xl-9 text-center">
                        <h2 className="h-3 mb-6">
                        tomorrow.ioのロードリスクスコア導入先は？
                        </h2>
                        <div className="row">
                            {industries.map((item, index) => (
                                <div className="col-md-6" key={index}>
                                    <img
                                        className="mb-3"
                                        width="100"
                                        src={item.icon}
                                        alt={item.name}
                                    />
                                    <h4 className="h-5">{item.name}</h4>
                                    <p
                                        className="mb-5 px-lg-7"
                                        dangerouslySetInnerHTML={{
                                            __html: item.desc,
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="text-center">
                    <a
                        className="btn btn-lg btn-primary text-nowrap"
                        href="https://www.tomorrow.io/api-signup/"
                        id="api-link-form-2"
                    >
                        今すぐ試す
                    </a>
                </div>
            </div>
        </section>

        <section className="c-section bg-api-features">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 ">
                        <h2 className="h-3 mb-4 text-center">道路リスクスコアを必要とするのは誰でしょう？</h2>
                        <p className="fs-lg text-center" dangerouslySetInnerHTML={{__html: Mikan('もしあなたの会社がかなり人数のドライバーを雇用しているのであれば、道路状況は、オペレーションやお客様、満足度と安全性に深く影響を与えます。雨に始まり、雪、気温、湿度、可視性、風といた気象状況は、道路状況に多大な影響を与え、交通関連の遅延や移動速度の減少を引き起こすでしょう。従業員が最適なルート計画、遅延の減少、安全性の向上を目指すために、道路状況を認識するための必要な情報を提供しましょう。')}}/>
                    </div>
                </div>
            </div>
        </section>

        <section className="c-section bg-testimonials h-auto text-white">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-xl-9 text-center">
                        <h2 className="h-3 mb-6">ベネフィット</h2>
                        <div className="row">
                            <div className="row">
                                {benefits.map((item, index) => (
                                    <div className="col-md-6" key={index}>
                                        <img
                                            className="mb-3"
                                            width="100"
                                            src={item.icon}
                                            alt={item.name}
                                        />
                                        <h4 className="h-5">{item.name}</h4>
                                        <p
                                            className="mb-5 px-lg-7"
                                            dangerouslySetInnerHTML={{
                                                __html: item.desc,
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="text-center">
                    <a
                        className="btn btn-lg btn-primary text-nowrap"
                        href="https://www.tomorrow.io/api-signup/"
                        id="api-link-form-3"
                    >
                        今すぐ試す
                    </a>
                </div>
            </div>
        </section>
    </Layout>
);
